<template>
<div class="recruitInfo">
  <Header></Header>
  <div class="info_content">
<!--    <p>官网首页></p>-->
    <p class="recruitTitle">{{$route.query.info.name}}</p>
    <p class="info_describe">岗位职责</p>
    <p style="line-height: 50px;font-size: 16px;" v-html="$route.query.info.info.zhize"></p>
    <p class="info_describe">任职要求</p>
    <p style="line-height: 50px;font-size: 16px;" v-html="$route.query.info.info.yaoqiu"></p>
    <p class="info_describe">公司福利</p>
    <p style="line-height: 50px;font-size: 16px;">五险一金 带薪年假 弹性工作 节日福利 员工旅游 全勤奖 加班补助 定期团建</p>
    <p class="info_describe">简历投递</p>
    <p style="line-height: 50px;font-size: 16px;">投递邮箱：</p>
    <p style="line-height: 50px;font-size: 16px;">欢迎投递！</p>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "index",
  components: {Footer, Header}
}
</script>

<style scoped lang="scss">
.recruitInfo{
  .info_content{
    width: 1300px;
    min-height: 500px;
    margin: 0 auto;
    margin-top: 60px;
    .recruitTitle{
      font-size: 32px;
      font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, Arial, sans-serif;
    }
    .info_describe{
      margin: 50px 0 20px 0;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>